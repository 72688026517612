<template>
  <div class="account-wrapper" v-if="openTabSet == 2">
    <h6 class="header-top">Notifications</h6>
    <p class="header-bottom">
      Manage your preferred notification settings. Who I Follow may still send
      you important notifications other than your preferred settings.
    </p>
    <div class="account-wrapper-content p-0" v-if="showLoader == 1">
      <div class="products-list-shim">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
          <content-placeholders-text :lines="3" />
        </content-placeholders>
      </div>
    </div>
    <div
      class="account-wrapper-content"
      id="accordionExample"
      v-if="showLoader == 0"
    >
      <div class="account-content-info d-block">
        <div
          id="headingOne"
          data-toggle="collapse"
          data-target="#collapseOne"
          aria-expanded="false"
          aria-controls="collapseOne"
        >
          <div>
            <h6 class="info-header mb-0">
              <button class="btn pl-0 text-left" type="button">Comments</button>
            </h6>
            <p class="info-text" v-if="textLoad">{{ commentNotifyText }}</p>
          </div>
          <div>
            <img
              src="../../../assets/images/arrow-right.png"
              alt=""
              class="fa-chevron-right"
            />
            <img
              src="../../../assets/images/arrow-down.png"
              alt=""
              class="fa-chevron-down"
            />
          </div>
        </div>

        <div
          id="collapseOne"
          class="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div class="card-body p-0 pt-3 d-flex flex-column">
            <div class="d-flex align-items-center">
              <span class="w-25">Push</span>
              <toggle-button
                class="toogle m-0"
                :value="false"
                :sync="true"
                :width="34"
                v-model="commentPush"
                @change="PushCommentNotify"
              />
            </div>
            <div class="d-flex align-items-center mt-2">
              <span class="w-25">Email</span>
              <toggle-button
                class="toogle m-0"
                :value="false"
                :sync="true"
                :width="34"
                v-model="commentEmail"
                @change="EmailCommentNotify"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="account-content-info d-block">
        <div
          id="headingTwo"
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
        >
          <div>
            <h6 class="info-header mb-0">
              <button class="btn pl-0 text-left collapsed" type="button">
                Friend Requests
              </button>
            </h6>
            <p class="info-text" v-if="textLoad">{{ friendReqNotifyText }}</p>
          </div>
          <div>
            <img
              src="../../../assets/images/arrow-right.png"
              alt=""
              class="fa-chevron-right"
            />
            <img
              src="../../../assets/images/arrow-down.png"
              alt=""
              class="fa-chevron-down"
            />
          </div>
        </div>
        <div
          id="collapseTwo"
          class="collapse"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
        >
          <div class="card-body p-0 pt-3 d-flex flex-column">
            <div class="d-flex align-items-center">
              <span class="w-25">Push</span>
              <toggle-button
                class="toogle m-0"
                :value="false"
                :sync="true"
                :width="34"
                v-model="friendPush"
                @change="PushFriendNotify"
              />
            </div>
            <div class="d-flex align-items-center mt-2">
              <span class="w-25">Email</span>
              <toggle-button
                class="toogle m-0"
                :value="false"
                :sync="true"
                :width="34"
                v-model="friendEmail"
                @change="EmailFriendNotify"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="account-content-info d-block">
        <div
          id="headingThree"
          data-toggle="collapse"
          data-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          <div>
            <h6 class="info-header mb-0">
              <button class="btn pl-0 text-left collapsed" type="button">
                Shop
              </button>
            </h6>
            <p class="info-text" v-if="textLoad">{{ shopNotifyText }}</p>
          </div>
          <div>
            <img
              src="../../../assets/images/arrow-right.png"
              alt=""
              class="fa-chevron-right"
            />
            <img
              src="../../../assets/images/arrow-down.png"
              alt=""
              class="fa-chevron-down"
            />
          </div>
        </div>
        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
        >
          <div class="card-body p-0 pt-3 d-flex flex-column">
            <div class="d-flex align-items-center">
              <span class="w-25">Push</span>
              <toggle-button
                class="toogle m-0"
                :value="false"
                :sync="true"
                :width="34"
                v-model="shopPush"
                @change="PushShopNotify"
              />
            </div>
            <div class="d-flex align-items-center mt-2">
              <span class="w-25">Email</span>
              <toggle-button
                class="toogle m-0"
                :value="false"
                :sync="true"
                :width="34"
                v-model="shopEmail"
                @change="EmailShopNotify"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="account-content-info d-block">
        <div
          id="headingFour"
          data-toggle="collapse"
          data-target="#collapseFour"
          aria-expanded="false"
          aria-controls="collapseFour"
        >
          <div>
            <h6 class="info-header mb-0">
              <button class="btn pl-0 text-left collapsed" type="button">
                Updates From Friends
              </button>
            </h6>
            <p class="info-text" v-if="textLoad">{{ updateNotifyText }}</p>
          </div>
          <div>
            <img
              src="../../../assets/images/arrow-right.png"
              alt=""
              class="fa-chevron-right"
            />
            <img
              src="../../../assets/images/arrow-down.png"
              alt=""
              class="fa-chevron-down"
            />
          </div>
        </div>
        <div
          id="collapseFour"
          class="collapse"
          aria-labelledby="headingFour"
          data-parent="#accordionExample"
        >
          <div class="card-body p-0 pt-3 d-flex flex-column">
            <div class="d-flex align-items-center">
              <span class="w-25">Push</span>
              <toggle-button
                class="toogle m-0"
                :value="false"
                :sync="true"
                :width="34"
                v-model="updatePush"
                @change="PushUpdateNotify"
              />
            </div>
            <div class="d-flex align-items-center mt-2">
              <span class="w-25">Email</span>
              <toggle-button
                class="toogle m-0"
                :value="false"
                :sync="true"
                :width="34"
                v-model="updateEmail"
                @change="EmailUpdateNotify"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "SettingNotification",
  props: {
    openTabSet: {
      type: Number,
    },
  },
  data() {
    return {
      commentPush: true,
      commentEmail: true,
      friendPush: true,
      friendEmail: true,
      shopPush: true,
      shopEmail: true,
      updatePush: true,
      updateEmail: true,
      previousData: [],
      commentNotifyText: "Push and Email",
      friendReqNotifyText: "Push and Email",
      shopNotifyText: "Push and Email",
      updateNotifyText: "Push and Email",
      textLoad: false,
      showLoader: 0,
    };
  },
  methods: {
    PushCommentNotify: function() {
      var formData = new FormData();
      if (this.commentPush == true) {
        formData.append("notification_id", 1);
        formData.append("push_enabled", 1);
        if (this.commentEmail == true) {
          this.commentNotifyText = "Push and Email";
        } else {
          this.commentNotifyText = "Push";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        formData.append("notification_id", 1);
        formData.append("push_enabled", 0);

        if (this.commentEmail == false) {
          this.commentNotifyText = "Off";
        } else {
          this.commentNotifyText = "Email";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    },
    EmailCommentNotify: function() {
      var formData = new FormData();
      if (this.commentEmail == true) {
        formData.append("notification_id", 1);
        formData.append("email_enabled", 1);

        if (this.commentPush == true) {
          this.commentNotifyText = "Push and Email";
        } else {
          this.commentNotifyText = "Email";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        formData.append("notification_id", 1);
        formData.append("email_enabled", 0);
        if (this.commentPush == false) {
          this.commentNotifyText = "Off";
        } else {
          this.commentNotifyText = "Push";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    },
    PushFriendNotify: function() {
      var formData = new FormData();
      if (this.friendPush == true) {
        formData.append("notification_id", 2);
        formData.append("push_enabled", 1);
        if (this.friendEmail == true) {
          this.friendReqNotifyText = "Push and Email";
        } else {
          this.friendReqNotifyText = "Push";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        formData.append("notification_id", 2);
        formData.append("push_enabled", 0);
        if (this.friendEmail == false) {
          this.friendReqNotifyText = "Off";
        } else {
          this.friendReqNotifyText = "Email";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    },
    EmailFriendNotify: function() {
      var formData = new FormData();
      if (this.friendEmail == true) {
        formData.append("notification_id", 2);
        formData.append("email_enabled", 1);
        if (this.friendPush == true) {
          this.friendReqNotifyText = "Push and Email";
        } else {
          this.friendReqNotifyText = "Email";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        formData.append("notification_id", 2);
        formData.append("email_enabled", 0);
        if (this.friendPush == false) {
          this.friendReqNotifyText = "Off";
        } else {
          this.friendReqNotifyText = "Push";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    },
    PushShopNotify: function() {
      var formData = new FormData();
      if (this.shopPush == true) {
        formData.append("notification_id", 4);
        formData.append("push_enabled", 1);
        if (this.shopEmail == true) {
          this.shopNotifyText = "Push and Email";
        } else {
          this.shopNotifyText = "Push";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        formData.append("notification_id", 4);
        formData.append("push_enabled", 0);
        if (this.shopEmail == false) {
          this.shopNotifyText = "Off";
        } else {
          this.shopNotifyText = "Email";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    },
    EmailShopNotify: function() {
      var formData = new FormData();
      if (this.shopEmail == true) {
        formData.append("notification_id", 4);
        formData.append("email_enabled", 1);
        if (this.shopPush == true) {
          this.shopNotifyText = "Push and Email";
        } else {
          this.shopNotifyText = "Email";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        formData.append("notification_id", 4);
        formData.append("email_enabled", 0);
        if (this.shopPush == false) {
          this.shopNotifyText = "Off";
        } else {
          this.shopNotifyText = "Push";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    },
    PushUpdateNotify: function() {
      var formData = new FormData();
      if (this.updatePush == true) {
        formData.append("notification_id", 3);
        formData.append("push_enabled", 1);
        if (this.updateEmail == true) {
          this.updateNotifyText = "Push and Email";
        } else {
          this.updateNotifyText = "Push";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        formData.append("notification_id", 3);
        formData.append("push_enabled", 0);
        if (this.updateEmail == false) {
          this.updateNotifyText = "Off";
        } else {
          this.updateNotifyText = "Email";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    },
    EmailUpdateNotify: function() {
      var formData = new FormData();
      if (this.updateEmail == true) {
        formData.append("notification_id", 3);
        formData.append("email_enabled", 1);
        if (this.updatePush == true) {
          this.updateNotifyText = "Push and Email";
        } else {
          this.updateNotifyText = "Email";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        formData.append("notification_id", 3);
        formData.append("email_enabled", 0);
        if (this.updatePush == false) {
          this.updateNotifyText = "Off";
        } else {
          this.updateNotifyText = "Push";
        }
        return axios.post(
          process.env.VUE_APP_API_URL + "notification/updateNotification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
    },
    getNotificationSettings() {
      this.showloader = 1;
      axios
        .get(
          process.env.VUE_APP_API_URL + "notification/getNotificationSettings"
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.previousData = response.data.data;
            window.test = this;
            this.previousData.forEach(function(item) {
              if (item.notification_id == 1) {
                if (item.push_enabled == 1 && item.email_enabled == 1) {
                  window.test.commentNotifyText = "Push and Email";
                } else if (item.push_enabled == 1) {
                  window.test.commentNotifyText = "Push";
                } else if (item.email_enabled == 1) {
                  window.test.commentNotifyText = "Email";
                } else {
                  window.test.commentNotifyText = "Off";
                }
                if (item.push_enabled == 1) {
                  window.test.commentPush = true;
                } else {
                  window.test.commentPush = false;
                }
                if (item.email_enabled == 1) {
                  window.test.commentEmail = true;
                } else {
                  window.test.commentEmail = false;
                }
              }
              if (item.notification_id == 2) {
                if (item.push_enabled == 1 && item.email_enabled == 1) {
                  window.test.friendReqNotifyText = "Push and Email";
                } else if (item.push_enabled == 1) {
                  window.test.friendReqNotifyText = "Push";
                } else if (item.email_enabled == 1) {
                  window.test.friendReqNotifyText = "Email";
                } else {
                  window.test.friendReqNotifyText = "Off";
                }
                if (item.push_enabled == 1) {
                  window.test.friendPush = true;
                } else {
                  window.test.friendPush = false;
                }
                if (item.email_enabled == 1) {
                  window.test.friendEmail = true;
                } else {
                  window.test.friendEmail = false;
                }
              }
              if (item.notification_id == 3) {
                if (item.push_enabled == 1 && item.email_enabled == 1) {
                  window.test.updateNotifyText = "Push and Email";
                } else if (item.push_enabled == 1) {
                  window.test.updateNotifyText = "Push";
                } else if (item.email_enabled == 1) {
                  window.test.updateNotifyText = "Email";
                } else {
                  window.test.updateNotifyText = "Off";
                }
                if (item.push_enabled == 1) {
                  window.test.updatePush = true;
                } else {
                  window.test.updatePush = false;
                }
                if (item.email_enabled == 1) {
                  window.test.updateEmail = true;
                } else {
                  window.test.updateEmail = false;
                }
              }
              if (item.notification_id == 4) {
                if (item.push_enabled == 1 && item.email_enabled == 1) {
                  window.test.shopNotifyText = "Push and Email";
                } else if (item.push_enabled == 1) {
                  window.test.shopNotifyText = "Push";
                } else if (item.email_enabled == 1) {
                  window.test.shopNotifyText = "Email";
                } else {
                  window.test.shopNotifyText = "Off";
                }
                if (item.push_enabled == 1) {
                  window.test.shopPush = true;
                } else {
                  window.test.shopPush = false;
                }
                if (item.email_enabled == 1) {
                  window.test.shopEmail = true;
                } else {
                  window.test.shopEmail = false;
                }
              }
            });
            this.textLoad = true;
            this.showloader = 0;
          }
        });
    },
  },
  mounted() {
    this.getNotificationSettings();
  },
};
</script>

<style></style>
