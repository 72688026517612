<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#passwordNewModal"
      ref="openPasswordNewModal"
    ></button>
    <div
      class="modal fade bd-example-modal-lg"
      id="passwordNewModal"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content" @keyup.enter="ReenterPassword(0)">
          <div class="modal-header">
            <h6 class="modal-title">Set new password</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeReenterPasswordModal"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <div class="user-info d-flex align-items-center mb-3">
              <div class="user-img" v-if="user">
                <img
                  v-if="user.picture == 'yes'"
                  :src="
                    Storage_URL + user.id + '/profile_picture.jpg?v=' + time
                  "
                  alt=""
                />
                <img
                  v-else
                  src="../../../assets/images/user-default-new.png"
                  alt=""
                />
              </div>
              <span v-if="user" class="text-capitalize user-name">{{
                user.name
              }}</span>
            </div>

            <div class="form-group">
              <input
                id="password"
                :class="{
                  'is-invalid': validationStatus($v.details.password),
                }"
                class="form-control"
                v-model.trim="$v.details.password.$model"
                spellcheck="false"
                autocomplete="off"
                trim
                :type="type1"
                required
                v-on:keyup="errorNull"
              />

              <span class="placeholder">Password</span>
              <i v-html="btnText" id="togglePassword" @click="showPassword"></i>
              <div v-if="!$v.details.password.required" class="invalid-tooltip">
                Please enter password
              </div>
              <div
                v-if="!$v.details.password.minLength"
                class="invalid-tooltip"
              >
                Minimum 8 characters required
              </div>
              <div
                v-if="!$v.details.password.maxLength"
                class="invalid-tooltip"
              >
                Maximum limit 40 characters.
              </div>
            </div>

            <div class="form-group">
              <input
                id="confirm_password"
                :class="{
                  'is-invalid': validationStatus(
                    $v.details.password_confirmation
                  ),
                }"
                class="form-control"
                v-model.trim="$v.details.password_confirmation.$model"
                spellcheck="false"
                autocomplete="off"
                trim
                :type="type2"
                required
                v-on:keyup="errorNull"
              />

              <span class="placeholder">Confirm Password</span>
              <i
                v-html="btnText2"
                id="toggleConfirmPassword"
                @click="showConfirmPassword"
              ></i>
              <div
                v-if="!$v.details.password_confirmation.required"
                class="invalid-tooltip"
              >
                Enter password again
              </div>
              <div
                v-if="!$v.details.password_confirmation.sameAsPassword"
                class="invalid-tooltip"
              >
                Passwords do not match.
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper px-3 pt-0 pb-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-100"
              @click="ReenterPassword(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#successModal"
      ref="openSuccessModal"
    ></button>
    <div
      class="modal fade bd-example-modal-lg"
      id="successModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="successModalLabel"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
      @shown="modalShown"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Password Updated</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeSuccessModal"
              @click="closeSuccessModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Your password has been updated. Now you have to log in again. Thank you!</p>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              type="button"
              class="btn btn-primary w-25"
              @click="closeSuccessModal"
            >OK</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as firestoreServe from "../../../database/firebase";

import {
  required,
  minLength,
  maxLength,
  sameAs,
} from "vuelidate/lib/validators";
import axios from "axios";

export default {
  name: "NewPasswordModal",
  data: function () {
    return {
      errorMessage: null,
      isLoadingArray: [],
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      isDisabled: true,
      details: {
        password: "",
        password_confirmation: "",
      },
      type1: "password",
      type2: "password",
      btnText: '<i class="fa fa-eye-slash"></i>',
      btnText2: '<i class="fa fa-eye-slash"></i>',
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  validations: {
    details: {
      password: { required, minLength: minLength(8), maxLength: maxLength(40) },
      password_confirmation: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    ...mapActions("notification", ["clearNotifications"]),
    ...mapActions({clearCartItems: "cart/clearCartItems" }),
    
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    modalShown() {
      document.querySelector('.modal-backdrop').addEventListener('click', this.closeSuccessModal);
    },
    showPassword: function () {
      if (this.type1 === "password") {
        this.type1 = "text";
        this.btnText = "<i class='fa fa-eye'></i>";
      } else {
        this.type1 = "password";
        this.btnText = "<i class='fa fa-eye-slash'></i>";
      }
    },

    showConfirmPassword: function () {
      if (this.type2 === "password") {
        this.type2 = "text";
        this.btnText2 = "<i class='fa fa-eye'></i>";
      } else {
        this.type2 = "password";
        this.btnText2 = "<i class='fa fa-eye-slash'></i>";
      }
    },

    errorNull: function () {
      this.errorMessage = null;
      if (
        this.details.password == "" ||
        this.details.password_confirmation == "" ||
        this.$v.$invalid
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },

    ReenterPassword: function (index) {
      this.isDisabled = true;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append("password", this.details.password);
      return axios
        .post(
          process.env.VUE_APP_API_URL + "user/changePasswordFromSettings",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$set(this.isLoadingArray, index, false);
            // this.$alert(response.data.message);
            var elem1 = this.$refs.closeReenterPasswordModal;
            elem1.click();
            var elem = this.$refs.openSuccessModal;
            elem.click();
          } else {
            this.$alert(response.data.message);
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },
    closeSuccessModal() {
      var elem = this.$refs.closeSuccessModal;
      elem.click();
      this.logout();
    },
    ...mapActions("auth", ["sendLogoutRequest", "getUserData"]),
    logout() {
      let loader = this.$loading.show({
        container: this.fullPage ? null : this.$refs.formContainer,

        color: "#ffffff",
        backgroundColor: "#333",
        opacity: 0.6,
      });
      this.sendLogoutRequest().then((response) => {
        if (response.data.statusCode == 200) {
          if (this.currentUserId !== "") {
            console.log(this.currentUserId);
            firestoreServe.updateUserOfflineStatus(this.currentUserId);
            this.currentUserId = "";
          }
          this.clearCartItems();
          this.clearNotifications();
          this.$router.push({ name: "Login" });
          loader.hide();
        } else if (response.data.message === "Unauthenticated") {
          // Handle Unauthenticated case, redirect to login
          this.$router.push({ name: "Login" });
          loader.hide();
        }
      });
    },
  },
  mounted() {
    this.$root.$on("openNewPasswordModal", () => {
      var elem = this.$refs.openPasswordNewModal;
      elem.click();
      this.details.password = "";
      this.details.password_confirmation = "";
      this.isDisabled = true;
      this.$v.details.password.$reset();
      this.$v.details.password_confirmation.$reset();
    });
  },
};
</script>
