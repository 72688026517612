<template>
  <div>
    <ChangeMobileNumber />
    <ReenterPasswordModal />
    <PasswordEnterModal />
    <DeleteAccountModal />
    <div class="account-wrapper" v-if="openTabSet == 1">
      <h6 class="header-top">Account</h6>
      <p class="header-bottom">
        Manage account details, password and recovery methods.
      </p>
      <div class="account-wrapper-content">
        <div class="account-content-info account-content-info-setting">
          <div>
            <h6 class="info-header">Login Info</h6>
            <div v-if="user" class="info-wrap">
              <span class="info-text" v-if="user">@{{ user.user_link }}</span>
              <span class="info-text" v-if="user">{{ user.email }}</span>
            </div>
          </div>
        </div>
        <div class="account-content-info" @click="MobileNumberModal()">
          <div>
            <h6 class="info-header">Mobile Number</h6>
            <div v-if="user">
              <p class="info-text" v-if="user.mob != null || user.mob != ''">
                {{ user.mob_code }}{{ user.mob }}
              </p>
              <p class="info-text" v-if="user.mob == null || user.mob == ''">
                Not set
              </p>
            </div>
          </div>

          <span>Change</span>
        </div>
        <div class="account-content-info" @click="ChangePasswordModal()">
          <div>
            <h6 class="info-header">Change Password</h6>
            <p class="info-text">
              To keep your account safe, set a secure password.
            </p>
          </div>
          <img src="../../../assets/images/arrow-right.png" alt="" />
        </div>
        <div class="account-content-info" @click="DeleteAccountModal()">
          <div>
            <h6 class="info-header">Delete Account</h6>
            <p class="info-text">
              Your account will be deactivated and your profile will be deleted.
            </p>
          </div>
          <img src="../../../assets/images/arrow-right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ChangeMobileNumber from "./ChangeMobileNumber.vue";
import DeleteAccountModal from "./DeleteAccountModal.vue";
import PasswordEnterModal from "./PasswordEnterModal.vue";
import ReenterPasswordModal from "./ReenterPasswordModal.vue";

export default {
  name: "SettingAccount",
  props: {
    openTabSet: {
      type: Number,
    },
  },
  components: {
    ChangeMobileNumber,
    ReenterPasswordModal,
    DeleteAccountModal,
    PasswordEnterModal,
  },
  methods: {
    MobileNumberModal: function () {
      this.$root.$emit("openChangeMobileNumber");
    },
    ChangePasswordModal: function () {
      this.$root.$emit("openPasswordEnterModal");
    },
    DeleteAccountModal: function () {
      this.$root.$emit("openDeleteAccountModal");
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style></style>
