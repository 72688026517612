<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#verifyMobileModal"
      ref="openVerifyMobileNumberModal"
      @click="startTimer()"
    ></button>
    <div
      class="modal fade bd-example-modal-lg"
      id="verifyMobileModal"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content" @keyup.enter="VerifyMobileNumber(0)">
          <div class="modal-header">
            <h6 class="modal-title">Verify Mobile Number</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeReenterPasswordModal"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <p class="mobile-number-text">
              To verify its you, please enter the OTP code sent on the mobile
              number <span>{{ mobCode }}{{ phone }}</span>
            </p>

            <b-form-group id="fieldset-1" label-for="code">
              <b-form-input
                id="code"
                :class="{
                  'is-invalid': validationStatus($v.details.code),
                }"
                v-model.trim="$v.details.code.$model"
                spellcheck="false"
                autocomplete="off"
                trim
                required
                type="text"
                v-on:keyup="errorNull"
              ></b-form-input>

              <span class="placeholder">OTP Code</span>
              <div
                v-if="!$v.details.code.required"
                class="invalid-tooltip"
              >
                OTP is required
              </div>
              <div
                v-if="!$v.details.code.minLength"
                class="invalid-tooltip"
              >
                Please enter 6 digit code
              </div>
              <div
                v-if="!$v.details.code.maxLength"
                class="invalid-tooltip"
              >
                Please enter 6 digit code
              </div>
              <div
                v-if="!$v.details.code.integer"
                class="invalid-tooltip"
              >
                Code must be a number
              </div>
            </b-form-group>

            <div class="account">
              <div class="password-text mb-0">
                <p class="text-center">
                  Didn't received the code ?
                  <span :disabled="counting" @click="startCountdown">
                    <countdown
                      ref="countdown"
                      v-if="counting"
                      :time="60000"
                      :auto-start="start"
                      @end="onCountdownEnd"
                      v-slot="{ totalSeconds }"
                      >Resend in {{ totalSeconds }} second (s).
                    </countdown>
                    <span v-else class="cursor-pointer">Resend Code</span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="event-btns-wrapper p-3">
            <button
              v-if="this.isDisabled == false"
              type="button"
              class="btn btn-primary w-100"
              block
              @click="VerifyMobileNumber(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Confirm</span>
            </button>
            <button
              v-else-if="this.isDisabled == true"
              type="button"
              class="btn btn-primary-disabled w-100"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Confirm</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  integer,
} from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import axios from "axios";
export default {
  name: "VerifyMobileNumberModal",
  data: function () {
    return {
      isDisabled: true,
      errorMessage: null,
      isLoadingArray: [],
      details: {
        code: "",
      },
      phone: "",
      mobCode: "",
      resendMessage: null,
      start: false,
      timerCount: 0,
      counting: true,
    };
  },
  validations: {
    details: {
      code: {
        required,
        integer,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    },
  },
  // watch: {
  //   timerCount: {
  //     handler(value) {
  //       if (value > 0) {
  //         setTimeout(() => {
  //           this.timerCount--;
  //         }, 1000);
  //       }
  //     },
  //     immediate: true, // This ensures the watcher is triggered upon creation
  //   },
  // },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    startCountdown: function () {
      this.counting = true;
      this.start = true;
      this.sendCode();
    },
    onCountdownEnd: function () {
      this.counting = false;
    },

    startTimer: function () {
      this.$refs.countdown.start();
    },

    async myTimer() {
      this.start = this.start + 1;
      await setTimeout(this.timerCount--, 1000);
      if (this.start < 60) {
        await this.myTimer();
      }
    },
    sendCode() {
      this.timerCount = 60;
      this.start = 0;
      this.myTimer();
      var formData = new FormData();
      formData.append("phone", this.phone);
      formData.append("mob_code", this.mobCode);
      return axios
        .post(
          process.env.VUE_APP_API_URL + "user/sendVerificationCode",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode == 401) {
            this.$alert(response.data.message);
          }
        });
    },
    VerifyMobileNumber: function (index) {
      this.isDisabled = true;
      this.errorMessage = null;
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
      formData.append("phone", this.phone);
      formData.append("mob_code", this.mobCode);
      formData.append("verfication_code", this.details.code);
      return axios
        .post(
          process.env.VUE_APP_API_URL + "user/updateMobileAfterVerification",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.$set(this.isLoadingArray, index, false);
            this.getUserData();
            this.$alert(response.data.message);
            let elem = this.$refs.closeReenterPasswordModal;
            elem.click();
          } else {
            this.$alert(response.data.message);
            let elem = this.$refs.closeReenterPasswordModal;
            elem.click();
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },
    errorNull: function () {
      this.errorMessage = null;
      if (this.details.code == "" || this.$v.$invalid) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
  },
  mounted() {
    this.$root.$on("openVerifyMobileNumberModal", () => {
      var elem = this.$refs.openVerifyMobileNumberModal;
      elem.click();
      this.startTimer();
      this.details.code = "";
      this.isDisabled = true;
      var details = localStorage.getItem("mob");
      var detailget = JSON.parse(details);
      var detailsCode = localStorage.getItem("mob_code");
      var detailgetCode = JSON.parse(detailsCode);
      this.phone = detailget;
      this.mobCode = detailgetCode;
      this.sendCode();
      this.$v.details.code.$reset()
    });
  },
};
</script>
