<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#deleteAccount"
      ref="openModalDeleteAccount"
    ></button>
    <div
      class="modal fade bd-example-modal-lg"
      id="deleteAccount"
      ref="modal-xl"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title">Delete your account on Who I Follow</h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeDeleteAccountModal"
            >
              <img src="../../../assets/close.png" />
            </button>
          </div>
          <div class="modal-body">
            <p class="mobile-number-text">
              When you delete your account, your profile, photos, videos,
              comments, likes, chat will be permanently deleted. Continue ?
            </p>
          </div>
          <div class="event-btns-wrapper px-3 pb-3">
            <button type="button" class="btn btn-secondary  w-50" data-dismiss="modal">
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary w-50"
              block
              @click="deleteAccount()"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import * as firestoreService from "../../../database/firestore";

export default {
  name: "DeleteAccount",
  data() {
    return {
      currentUserId: "",
      userId: localStorage.getItem("userId"),
    };
  },

  methods: {
    ...mapActions("auth", ["sendLogoutRequest", "getUserData"]),
    deleteAccount: function () {
      this.subDeleteAccount();
    },

    async subDeleteAccount() {
      if (this.currentUserId) {
        var elem = this.$refs.closeDeleteAccountModal;
        elem.click();
        let loader = this.$loading.show({
          // Optional parameters
          container: this.fullPage ? null : this.$refs.formContainer,

          color: "#ffffff",
          backgroundColor: "#333",
          opacity: 0.6,
        });

        const formData = new FormData();
        formData.append("chat_id", this.currentUserId);
        return axios
          .post(
            process.env.VUE_APP_API_URL + "user/deleteAccountAndUser",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async (response) => {
            if (response.data.statusCode == 200) {
              await this.deleteTeams();
              this.sendLogoutRequest().then((response) => {
                if (response.data.statusCode == 200) {
                  loader.hide();
                  this.$router.push({ name: "Login" });
                }
              });
            } else {
              alert("Please Try Again. " + response.data.message);
            }
          });
      } else {
        this.getUserChatId();
        this.subDeleteAccount();
      }
    },

    async deleteTeams() {
      const queryAll = firestoreService.allRooms(this.currentUserId);
      const { data, docs } = await firestoreService.getRooms(queryAll);
      this.totalTeams = docs[docs.length - 1];
      data.forEach(async (team) => {
        if (team.chatType == "team") {
          await this.makeOtherAdmin(team);
        } else {
          await this.leaveChat(team);
        }
      });
      await this.deleteUser();
    },

    async makeOtherAdmin(team) {
      // check if you are the only admin
      const teamSUserRole = team.userRole;
      var admincount = 0;
      for (const userRole of teamSUserRole) {
        if (userRole.id != this.currentUserId && userRole.admin == "1") {
          admincount++;
        }
      }
      if (admincount > 0) {
        await this.leaveChat(team);
      } else {
        var i = 0;
        var getID = "";
        for (const userRole of teamSUserRole) {
          if (userRole.id != this.currentUserId) {
            getID = i;
          }
          i++;
        }

        if (getID) {
          teamSUserRole[getID].admin = "1";
          await firestoreService.updateRoom(team.id, {
            userRole: teamSUserRole,
            // lastUpdated: new Date(),
          });
        }

        await this.leaveChat(team);
      }
    },
    async leaveChat(team) {
      await firestoreService.removeRoomUser(team.id, this.currentUserId);

      if (team.chatType == "team") {
        // Remove from User Role Array
        const teamSUserRole = team.userRole;
        var i = 0;
        for (const userRole of teamSUserRole) {
          if (userRole.id == this.currentUserId) {
            team.userRole.splice(i, 1);
          }
          i++;
        }
        await firestoreService.updateRoom(team.id, {
          userRole: team.userRole,
        });

        // Remove from User Count Array
        const teamSUserCount = team.userCount;
        var s = 0;
        for (const userCount of teamSUserCount) {
          if (userCount.id == this.currentUserId) {
            team.userCount.splice(s, 1);
          }
          s++;
        }
        await firestoreService.updateRoom(team.id, {
          userCount: team.userCount,
          // lastUpdated: new Date(),
        });
      }
    },

    async deleteUser() {
      await firestoreService.deleteUser(this.currentUserId);
    },
    async getUserChatId(){
    const promiseUser = await firestoreService.getUserCid(this.userId.toString());
    this.currentUserId = promiseUser.data[0].id;
    console.log(this.currentUserId,"current User id")
    }
  },
  mounted() {
    this.getUserChatId();
    this.$root.$on("openDeleteAccountModal", () => {
      var elem = this.$refs.openModalDeleteAccount;
      elem.click();
    });
  },
};
</script>

<style></style>
