<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner/>
      <div class="shop-side p-md-0">
        <SettingLeft :openTabSet="openTabSet" />
        <div class="shop-page-products p-0">
          <div class="col-12 p-0">
            <SettingAccount :openTabSet="openTabSet" />
            <SettingNotification :openTabSet="openTabSet" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import SettingLeft from "../components/Settings/SettingLeft.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import SettingAccount from "../components/Settings/SettingRight/SettingAccount.vue";
import SettingNotification from "../components/Settings/SettingRight/SettingNotification.vue";
export default {
  name: "Settings",
  components: {
    Navbar,
    LeftMenu,
    SettingLeft,
    Banner,
    SettingAccount,
    SettingNotification,
  },
  data() {
    return {
      openTabSet: 1,
    };
  },
  mounted() {
    this.$root.$on("openTabSetting", (id) => {
      this.openTabSet = id;
    });
  },
};
</script>

<style></style>
