<template>
  <div class="setting-left-menu search">
    <div class="heading">
      <h6>Settings</h6>
    </div>
    <ul class="setting-menu-list search-list">
      <li
        class="search-list-item"
        v-bind:class="[openTabSet == 1 ? 'active' : '']"
        @click="Account"
      >
        <img
          v-if="openTabSet == 1"
          src="../../assets/images/setting-account-filled.png"
        />
        <img v-else src="../../assets/images/setting-account.png" />
        Account
      </li>
      <li
        class="search-list-item"
        v-bind:class="[openTabSet == 2 ? 'active' : '']"
        @click="Notification"
      >
        <img
          v-if="openTabSet == 2"
          src="../../assets/images/setting-notification-filled.png"
        />
        <img v-else src="../../assets/images/setting-notification.png" />
        Notifications
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SettingLeft",
  props: {
    openTabSet: {
      type: Number,
    },
  },
  methods: {
    Account() {
      this.$root.$emit("openTabSetting", 1, 0);
    },
    Notification() {
      this.$root.$emit("openTabSetting", 2, 0);
    },
  },
};
</script>
